import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { Link } from 'react-router-dom';
import { CognitoConfig } from '../../cognito-config';
import troopLogo from '../../assets/troop-logo-dark.png';
import WelcomeMessage from '../../components/WelcomeMessage/WelcomeMessage';
import { useEffect, useRef } from 'react';

const LogoutPage = () => {
  const pageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    pageRef?.current?.focus();
  }, []);

  const poolData = {
    UserPoolId: CognitoConfig.UserPoolId,
    ClientId: CognitoConfig.ClientId,
  };

  const userPool = new CognitoUserPool(poolData);
  const user: CognitoUser | null = userPool.getCurrentUser();

  if (user !== null) {
    user.signOut();
  }

  return (
    <div className="md:h-full md:flex" ref={pageRef}>
      <div className="mt-8 md:mt-auto md:grid md:grid-cols-2 md:gap-x-2 md:items-stretch h-full w-full">
        <WelcomeMessage/>
        <div className="flex flex-col items-center justify-center">
          <img src={troopLogo} alt="Troop Logo" className="w-60 mb-24"/>
          <p className="mb-4">You're logged out. Thanks for visiting and come back soon.</p>
          <Link to="/login" className="text-emerald-700 font-bold underline">Login again</Link>
        </div>
      </div>
    </div>
  );
};

export default LogoutPage;
