import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import PhotoAlbumPage from './pages/PhotoAlbum/PhotoAlbumPage';
import NotFoundPage from './pages/Errors/NotFoundPage';
import LoginPage from './pages/Login/LoginPage';
import LogoutPage from './pages/Logout/Logout';
import HomePage from './pages/Home/HomePage';
import ForgotPasswordPage from './pages/ForgotPassword/ForgotPasswordPage';
import EventsPage from './pages/Events/EventsPage';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/events" element={<EventsPage />}/>
        <Route path="/photos" element={<PhotoAlbumPage/>}/>
        <Route path="/login" element={<LoginPage/>}/>
        <Route path="/forgot" element={<ForgotPasswordPage/>}/>
        <Route path="/logout" element={<LogoutPage/>}/>
        <Route path="/app" element={<App/>}/>
        <Route path="*" element={<NotFoundPage/>}/>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
