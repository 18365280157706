import explorerLogo from '../../assets/explorer-logo.png';

const WelcomeMessage = () => {
  return (
    <div className="hidden md:flex md:flex-col md:items-center md:justify-center bg-zinc-700">
      <h1 className="text-3xl text-white mb-24">Welcome to the Trail Life Explorer</h1>
      <img src={explorerLogo} alt="Trail Life Explorer" className="w-1/2"/>
      <p className="mt-8 sm:mx-16 md:mx-24 lg:mx-30 xl:mx-48 text-white">
        Explorer is a member supported website dedicated to the families of Trail Life Troop 7777.
        Members may request access by contacting us at {' '}
        <strong><a href="mailto:traillife7777@gmail.com" className="underline">traillife7777@gmail.com</a></strong>.
      </p>
    </div>
  );
};

export default WelcomeMessage;
