export interface TrailLifeEvent {
  EventId: string;
  Name: string;
  Date: string;
}

export interface TrailLifeImage {
  ImageId: string;
  EventId: string;
  Caption: string;
  Url: string;
}

export interface ImageCardListProps {
  imagesList: TrailLifeImage[];
  eventsList: TrailLifeEvent[];
  setPhotoIndex(photoIndex: number): void;
  setIsOpen(isOpen: boolean): void;
}

const ImageCardList = (props: ImageCardListProps) => {

  const handleImageOnClick = (image: TrailLifeImage) => {
    props.setIsOpen(true);

    for (let i = 0; i < props.imagesList.length; i++) {
      const tempImage: TrailLifeImage = props.imagesList[i];
      if (image.ImageId === tempImage.ImageId) {
        props.setPhotoIndex(i);
      }
    }
  }

  const getImageCards = (imagesArr: TrailLifeImage[]) => {
    return imagesArr.map((image: TrailLifeImage, index: number) => {

      return (
        <div className="w-auto" key={index}>
          <div
            className="inline-block border border-gray-300 bg-white p-3"
          >
            <button onClick={() => {handleImageOnClick(image)}}>
              <img src={image.Url} alt={image.Caption} />
            </button>
          </div>
        </div>
      );
    });
  };

  const eventsList = props.eventsList.map((event: TrailLifeEvent, index: number) => {
    const images = props.imagesList.filter((image: TrailLifeImage) => {
      return image.EventId === event.EventId;
    });

    return (
      <div key={index} className="mb-16">
        <h2 className="text-2xl md:text-base border-b mb-6">
          {event.Name}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {getImageCards(images)}
        </div>
      </div>
    );
  });

  return (
    <div>
      {eventsList}
    </div>
  );
};

export default ImageCardList;
