import { getDateParts } from '../../utils/DateUtils';

export interface CalendarDateProps {
  date: string;
  time: string;
}

const CalendarDate = (props: CalendarDateProps) => {

  const dateParts = getDateParts(`${props.date} ${props.time}`);

  return (
    <p className="flex flex-col border border-gray-900 bg-emerald-700 text-white py-2">
      <span className="block text-2xl sm:text-base">{dateParts.monthAbbrev}</span>
      <span className="block text-3xl">{dateParts.day}</span>
    </p>
  )
}

export default CalendarDate;
