import { useEffect, useRef, useState } from 'react';
import { CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import axios from 'axios';
import { ICalendarEvent } from '../../components/CalendarEvent/CalendarEvent';
import { getDateParts, isDateInPast } from '../../utils/DateUtils';
import Page from '../../components/Page/Page';
import CalendarEventsList from '../../components/CalendarEventsList/CalendarEventsList';
import CalenderEventFilter from '../../components/CalendarEventFilter/CalenderEventFilter';

const EventsPage = () => {
  const pageRef = useRef<HTMLHeadingElement>(null);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);

  const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID || '',
    ClientId: process.env.REACT_APP_CLIENT_ID || '',
  };
  const userPool = new CognitoUserPool(poolData);
  const user: CognitoUser | null = userPool.getCurrentUser();

  useEffect(() => {
    user?.getSession((error: Error, session: null | CognitoUserSession) => {
      if (error) {
        console.log(`ERROR`, error);
        return;
      }

      if (!session) {
        return;
      }

      axios.get('https://api.traillife7777.com/calendar', {
        headers: {
          authorization: session.getIdToken().getJwtToken(),
        },
      })
        .then(res => {
          const records = res.data.Items.map((record: ICalendarEvent) => {
            // Adding Date object using the date and time.
            record.Date = new Date(`${record.D} ${record.T}`);
            return record;
          }).filter((record: ICalendarEvent) => {
            // Automatically include events for today.
            const dateObj = getDateParts(record.Date.toString());
            if (dateObj.day === new Date().getDate()) {
              return true;
            }

            // For events that aren't happening today, only capture those from today.
            return !isDateInPast(record.Date.toString());
          });

          // Sort the records by the Date field.
          records.sort((obj1: ICalendarEvent, obj2: ICalendarEvent) => {
            return Date.parse(obj1.Date.toString()) - Date.parse(obj2.Date.toString());
          });

          setCalendarEvents(records);
          setFilteredEvents(records);
        })
        .catch(err => {
          console.log(`ERROR`, err);
        });
    });

    if (!user) {
      return;
    }
  }, []);

  const handleEventsOnFilter = (troopLevel: string[]) => {
    const filteredEventsList = calendarEvents.filter((event: ICalendarEvent) => {
      return troopLevel.indexOf(event.Lvl) > -1;
    });

    setFilteredEvents(filteredEventsList);
  }

  return (
    <Page pageRef={pageRef}>
      <div className="grid grid-cols-1 gap-y-4 md:grid-cols-2 lg:grid-cols-4 md:gap-4">
        <div className="border bg-white p-4 lg:col-span-3">
          <h1 className="text-4xl md:text-3xl mb-4" ref={pageRef}>Upcoming Events</h1>
          <CalendarEventsList calendarEvents={filteredEvents} />
        </div>
        <div className="border bg-white p-4">
          <h2 className="text-2xl">About the Events Page</h2>
          <p className="text-2xl sm:text-base mb-4 md:mb-8">
            This page includes the upcoming events for the troop. Look for events
            that have your troop's tag. The tags include the icon and the name of the troop. This will help
            you easily identify the events that are relevant for you.
          </p>
          <h2 className="text-2xl mb-2">Filter by Your Troop</h2>
          <p className="text-2xl sm:text-base mb-4 md:mb-8">
            This filter will only show the events that you're interested in. Select the checkbox to filter the dates by your troop.
          </p>
          <h3 className="text-2xl sm:text-base font-bold mb-2">Only show these troops:</h3>
          <CalenderEventFilter filterHandler={handleEventsOnFilter} />
        </div>
      </div>
    </Page>
  )
}

export default EventsPage;
