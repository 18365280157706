import Page from '../../components/Page/Page';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import CalendarEventsList from '../../components/CalendarEventsList/CalendarEventsList';
import { ICalendarEvent } from '../../components/CalendarEvent/CalendarEvent';
import { getDateParts, isDateInPast } from '../../utils/DateUtils';

const HomePage = () => {
  const [calendarEvents, setCalendarEvents] = useState([]);
  const pageRef = useRef<HTMLHeadingElement>(null);

  const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID || '',
    ClientId: process.env.REACT_APP_CLIENT_ID || '',
  };
  const userPool = new CognitoUserPool(poolData);
  const user: CognitoUser | null = userPool.getCurrentUser();

  useEffect(() => {
    user?.getSession((error: Error, session: null | CognitoUserSession) => {
      if (error) {
        console.log(`ERROR`, error);
        return;
      }

      if (!session) {
        return;
      }

      axios.get('https://api.traillife7777.com/calendar', {
        headers: {
          authorization: session.getIdToken().getJwtToken(),
        },
      })
        .then(res => {
          const records = res.data.Items.map((record: ICalendarEvent) => {
            // Adding Date object using the date and time.
            record.Date = new Date(`${record.D} ${record.T}`);
            return record;
          }).filter((record: ICalendarEvent) => {
            // Automatically include events for today.
            const dateObj = getDateParts(record.Date.toString());
            if (dateObj.day === new Date().getDate()) {
              return true;
            }

            // For events that aren't happening today, only capture those from today.
            return !isDateInPast(record.Date.toString());
          });

          // Sort the records by the Date field.
          records.sort((obj1: ICalendarEvent, obj2: ICalendarEvent) => {
            return Date.parse(obj1.Date.toString()) - Date.parse(obj2.Date.toString());
          });

          // Only show the first 5 events on the home page.
          const first5Events = records.slice(0, 5);

          setCalendarEvents(first5Events);
        })
        .catch(err => {
          console.log(`ERROR`, err);
        });
    });

    if (!user) {
      return;
    }
  }, []);

  return (
    <Page pageRef={pageRef}>
      <div className="grid grid-cols-1 gap-y-4 md:grid-cols-2 lg:grid-cols-5 md:gap-4">
        <div className="border bg-white p-4 lg:col-span-3">
          <h1 className="text-4xl md:text-3xl mb-4" ref={pageRef}>Welcome to Trail Life Explorer</h1>
          <p className="mb-12 text-2xl sm:text-base">Trail Life Explorer is a password protected website available to members of the troop. This site
            will serve as a place to view upcoming events, recent news, and our troop photo album.</p>

          <h2 className="text-2xl mb-2">Share your event photos</h2>
          <p className="text-2xl sm:text-base">Please consider sharing your pictures of Trail Life events so we can add them to the album. Provide
            the event name and date along with the pictures. You can email them to {' '}
            <a href="mailto:traillife7777@gmail.com" className="text-emerald-700 font-bold underline">traillife7777@gmail.com</a>.
            Click here to view the <Link to="/photos" className="text-emerald-700 font-bold underline">photos</Link>.
          </p>
        </div>
        <div className="border bg-white p-4 lg:col-span-2">
          <div className="sm:grid sm:grid-cols-2 md:grid-cols-3">
            <h2 className="text-3xl md:text-2xl md:col-span-2 mb-2 sm:mb-4">Upcoming Events</h2>
            <Link to="/events" className="text-xl sm:text-base inline-block text-emerald-700 text-right mb-8 sm:mb-0">View All</Link>
          </div>
          <CalendarEventsList calendarEvents={calendarEvents} />
        </div>
      </div>
    </Page>
  );
};

export default HomePage;
