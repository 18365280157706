import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CognitoConfig } from '../../cognito-config';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import troopLogo from '../../assets/troop-logo-dark.png';
import explorerLogo from '../../assets/explorer-logo.png';
import Spinner from '../../components/Spinner/Spinner';
import NewPasswordRequired from '../../components/NewPasswordRequired/NewPasswordRequired';
import WelcomeMessage from '../../components/WelcomeMessage/WelcomeMessage';

const LoginPage = () => {
  const pageRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginFailed, setLoginFailed] = useState(false);
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [processing, setProcessing] = useState(false);
  const userPool = useMemo(() => new CognitoUserPool(CognitoConfig), []);

  useEffect(() => {
    window.scrollTo(0, 0);
    pageRef?.current?.focus();
  }, []);

  const handleUsernameOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const checkLoginStatus = useCallback(() => {
    const user: CognitoUser | null = userPool.getCurrentUser();

    if (user !== null) {
      navigate('/');
    }
  }, [userPool, navigate]);

  const handleLoginOnClick = () => {
    setProcessing(true);

    const authenticatedData = {
      Username: username,
      Password: password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticatedData);

    const userData = {
      Username: username.toLowerCase(),
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function onSuccess() {
        navigate('/');
      },
      onFailure: function onFailure() {
        setProcessing(false);
        setLoginFailed(true);
      },
      newPasswordRequired: function onNewPasswordRequire() {
        setNewPasswordRequired(true);
      },
    });
  };

  const getSpinner = () => {
    if (processing) {
      return (
        <Spinner cssClass="text-gray-400 inline ml-2"/>
      );
    }

    return '';
  };

  const getLoginFailedMessage = () => {
    if (loginFailed) {
      return (
        <p className="mt-3 text-red-600">We can't find a user with the username and password you provided. Please try again.</p>
      );
    }

    return '';
  };

  useEffect(() => {
    checkLoginStatus();
  }, [checkLoginStatus]);

  // @ts-ignore
  const getLoginForm = () => {
    if (newPasswordRequired) {
      return '';
    }

    return (
      <div className="w-80" ref={pageRef}>
        <img src={troopLogo} alt="Troop Logo" className="w-60 mb-12"/>
        <label className="block mb-4">
          <span className="text-gray-700">Email Address</span>
          <input
            type="text"
            className="mt-1 block w-full"
            value={username}
            onChange={handleUsernameOnChange}
          />
        </label>
        <label className="block mb-8">
          <span className="text-gray-700">Password</span>
          <input
            type="password"
            className="mt-1 block w-full"
            value={password}
            onChange={handlePasswordOnChange}
          />
        </label>
        <div className="text-right border-b border-gray-500 pb-8 mb-4">
          <button
            onClick={handleLoginOnClick}
            disabled={processing}
            className="border px-4 py-2 border-gray-500 disabled:border-gray-400 disabled:text-gray-400 disabled:w-30"
          >Login {getSpinner()}</button>
          {getLoginFailedMessage()}
        </div>
        <p className="text-center mb-5">
          <Link to="/forgot" className="text-emerald-700 font-bold underline">Forgot Password</Link>
        </p>
      </div>
    );
  };

  // @ts-ignore
  const getNewPasswordRequireForm = () => {
    if (!newPasswordRequired) {
      return '';
    }

    return (
      <NewPasswordRequired
        username={username}
        password={password}
      />
    );
  };

  return (
    <div className="md:h-full md:flex">
      <div className="mt-8 md:mt-auto md:grid md:grid-cols-2 md:gap-x-2 md:items-stretch h-full w-full">
        <WelcomeMessage />
        <div className="flex items-center justify-center">
          {getLoginForm()}
          {getNewPasswordRequireForm()}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
