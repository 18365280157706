import Page from '../../components/Page/Page';
import { useRef } from 'react';

const NotFoundPage = () => {
  const pageRef = useRef<HTMLHeadingElement>(null);

  return (
    <Page pageRef={pageRef}>
      <h1 className="text-3xl" ref={pageRef}>Not Found</h1>
    </Page>
  );
};

export default NotFoundPage;
