import { useCallback, useEffect, useMemo, useState } from 'react';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import PrimaryNav from '../PrimaryNav/PrimaryNav';
import { CognitoConfig } from '../../cognito-config';
import { useNavigate } from 'react-router-dom';
import './Page.css';

export interface PageProps {
  pageRef: any;
  children: any;
}

const Page = (props: PageProps) => {
  const navigate = useNavigate();
  const [authenticatedStatus, setAuthenticatedStatus] = useState(false);
  const userPool = useMemo(() => new CognitoUserPool(CognitoConfig), []);

  const checkLoginStatus = useCallback(() => {
    const user: CognitoUser | null = userPool.getCurrentUser();

    if (user !== null) {
      setAuthenticatedStatus(true);
    }

    if (user === null) {
      navigate('/login');
    }
  }, [userPool, navigate]);

  useEffect(() => {
    checkLoginStatus();
  }, [checkLoginStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
    props.pageRef.current.focus();
  }, [props.pageRef]);

  return (
    <div className="flex flex-col h-screen">
      <header className="fixed top-0 left-0 w-full bg-zinc-700 text-white py-4">
        <PrimaryNav
          isLoggedIn={authenticatedStatus}
        />
      </header>
      <div className="flex flex-grow">
        <div className="flex w-full h-full">
          <main className="bg-gray-100 flex-initial basis-full h-full px-4 pb-4 pt-32">
            {props.children}
          </main>
        </div>
      </div>
    </div>
  );
};

export default Page;
