import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { TrailLifeImage } from '../ImageCardList/ImageCardList';

interface LightBoxWrapperProps {
  images: TrailLifeImage[];
  photoIndex: number;
  isOpen: boolean;
  setPhotoIndex(photoIndex: number): void;
  setIsOpen(isOpen: boolean): void;
}

const LightBoxWrapper = (props: LightBoxWrapperProps) => {

  const {images, photoIndex, isOpen, setPhotoIndex, setIsOpen} = props;
  const mainSrc: TrailLifeImage = images[photoIndex];
  const nextSrc: TrailLifeImage = images[(photoIndex + 1) % images.length];
  const prevSrc: TrailLifeImage = images[(photoIndex + images.length - 1) % images.length];

  if (!isOpen) {
    return <></>;
  }

  return (
    <div>
      <button type="button" onClick={() => setIsOpen(true)}>
        Open Lightbox
      </button>

      <Lightbox
        mainSrc={mainSrc.Url}
        nextSrc={nextSrc.Url}
        prevSrc={prevSrc.Url}
        onCloseRequest={() => setIsOpen(false)}
        onMovePrevRequest={() =>
          setPhotoIndex((photoIndex + images.length - 1) % images.length)
        }
        onMoveNextRequest={() =>
          setPhotoIndex((photoIndex + 1) % images.length)
        }
      />
    </div>
  );
}

export default LightBoxWrapper;
