import { NavLink } from 'react-router-dom';
import troopLogo from '../../assets/troop-logo.png';
import './PrimaryNav.css';
import { useState } from 'react';

interface PrimaryNavProps {
  isLoggedIn: boolean;
}

const PrimaryNav = (props: PrimaryNavProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const linksArr = [
    <NavLink to="/">Home</NavLink>,
    <NavLink to="/photos">Photos</NavLink>,
    <NavLink to="/events">Events</NavLink>
  ];

  if (props.isLoggedIn) {
    linksArr.push(<NavLink to="/logout">Logout</NavLink>);
  } else {
    linksArr.push(<NavLink to="/login">Login</NavLink>);
  }

  const links = linksArr.map((link: JSX.Element, index: number) => {
    return (
      <li className="text-left text-3xl md:text-base md:inline-flex md:items-end md:justify-center md:mx-2 lg:mx-4 md:h-full px-8 py-4 md:p-0 md:pb-1" key={index}>
        {link}
      </li>
    );
  });

  const handleMenuOnClick = () => {
    setMenuIsOpen(!menuIsOpen);
  }

  const menuCssClass = menuIsOpen ? 'md:block h-full md:text-right mobile-menu-open pt-8' : 'hidden md:block h-full md:text-right'

  const getIcon = () => {
    if (menuIsOpen) {
      return <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" className="w-6" role="img"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
        <path fill="currentColor"
              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>
      </svg>
    }

    return <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" className="w-6" role="img"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path fill="currentColor"
            d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z" />
    </svg>
  }

  return (
    <div className="grid grid-cols-2 md:mx-auto grid-container primary-nav p-4">
      <a href="/">
        <img src={troopLogo} alt="Trail Life USA logo" className="w-48"/>
      </a>
      <nav aria-label="main" className="h-full text-right md:text-left">
        <ul className={menuCssClass}>
          {links}
        </ul>
        <div className="inline-flex align-center justify-center md:hidden text-right h-full">
          <button className="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleMenuOnClick}>
            {getIcon()}
          </button>
        </div>
      </nav>
    </div>
  );
};

export default PrimaryNav;
