import CalendarDate from '../CalendarDate/CalendarDate';
import woodlandsIcon from '../../assets/woodlands.jpg';
import navigatorsIcon from '../../assets/navigators.jpg';
import adventurersIcon from '../../assets/adventurers.jpg';

export interface ICalendarEvent {
  Id: string;
  Lvl: string;
  Name: string;
  Desc: string;
  D: string;
  T: string;
  Date: Date
}

export interface CalendarEventProps {
  calendarEvent: ICalendarEvent;
}

const CalendarEvent = (props: CalendarEventProps) => {
  const { calendarEvent } = props;

  const getPatrolTag = () => {
    const woodlandsElem = (
      <span className="inline-block border rounded px-2 py-1 mr-2 mb-3">
        <img src={woodlandsIcon} alt="Woodlands" className="inline mr-2" aria-hidden="true" />
        Woodlands
      </span>
    );
    const navigatorsElem = (
      <span className="inline-block border rounded px-2 py-1 mr-2 mb-3">
        <img src={navigatorsIcon} alt="Navigators" className="inline mr-2" aria-hidden="true" />
        Navigators
      </span>
    )
    const adventurersElem = (
      <span className="inline-block border rounded px-2 py-1 mb-3">
        <img src={adventurersIcon} alt="Adventurers" className="inline mr-2" aria-hidden="true" />
        Adventurers
      </span>
    )
    if (calendarEvent.Lvl === '1') {
      return woodlandsElem;
    } else if (calendarEvent.Lvl === '2') {
      return navigatorsElem;
    } else if (calendarEvent.Lvl === '3') {
      return adventurersElem;
    } else if (calendarEvent.Lvl === '4') {
      return (
        <>
          {navigatorsElem}
          {adventurersElem}
        </>
      )
    } else if (calendarEvent.Lvl === '5') {
      return (
        <>
          {woodlandsElem}
          {navigatorsElem}
          {adventurersElem}
        </>
      )
    } else {
      return null;
    }
  }

  return (
    <div className="flex">
      <div className="w-20 text-center">
        <CalendarDate date={calendarEvent.D} time={calendarEvent.T} />
      </div>
      <div className="pl-4 w-full">
        <h3 className="text-3xl sm:text-xl">{calendarEvent.Name}</h3>
        <p className="text-2xl sm:text-base">{calendarEvent.Desc}</p>
        <p className="text-2xl sm:text-base mb-2">{calendarEvent.T}</p>
        <p className="text-2xl sm:text-base">{getPatrolTag()}</p>
      </div>
    </div>
  )
};

export default CalendarEvent;
