export const monthAbbrevNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const getDateParts = (eventDate: string) => {
  const date = new Date(eventDate);
  const month = monthNames[date.getMonth()];
  const monthAbbrev = monthAbbrevNames[date.getMonth()];
  const dayOfWeek = days[date.getDay()];
  const dayOfWeekAbbrev = days[date.getDay()].substring(0,3);
  const day = date.getDate();
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
  let hours;

  if (date.getHours() > 0 && date.getHours() <= 12) {
    hours= '' + date.getHours();
  } else if (date.getHours() > 12) {
    hours= '' + (date.getHours() - 12);
  } else if (date.getHours() === 0) {
    hours= '12';
  }

  return {
    month,
    monthAbbrev,
    dayOfWeek,
    dayOfWeekAbbrev,
    day,
    minutes,
    hours,
    ampm,
    timeValue: `${hours}:${minutes} ${ampm} EST`,
    dateValue: `${dayOfWeek}, ${month} ${day}`
  }
}

export const isDateInPast = (date: string) => {
  const today = new Date();
  const eventDate = new Date(date);

  eventDate.setDate(eventDate.getDate() - 1)
  return eventDate.setHours(0,0,0,0) - today.setHours(0,0,0,0) <= 0;
}
