import CalendarEvent, { ICalendarEvent } from '../CalendarEvent/CalendarEvent';

interface CalendarEventsListProps {
  calendarEvents: ICalendarEvent[];
}

const CalendarEventList = (props: CalendarEventsListProps) => {

  const getCalendarEventsList = () => {
    const calendarEvents = props.calendarEvents.sort()

    return calendarEvents.map((calendarEvent: ICalendarEvent) => {
      return (
        <li key={calendarEvent.Id} className="border-b mb-3">
          <CalendarEvent calendarEvent={calendarEvent} />
        </li>
      )
    });
  }

  return (
    <ul>
      {getCalendarEventsList()}
    </ul>
  )
};

export default CalendarEventList;
