import Page from '../../components/Page/Page';
import ImageCardList, { TrailLifeEvent, TrailLifeImage } from '../../components/ImageCardList/ImageCardList';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import LightBoxWrapper from '../../components/LightBoxWrapper/LightBoxWrapper';

export interface IEvent {
  Date: string;
  date?: Date;
  EventId: string;
  Name: string;
}

const PhotoAlbumPage = () => {
  const pageRef = useRef<HTMLHeadingElement>(null);
  const [events, setEvents] = useState([]);
  const [images, setImages] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID || '',
    ClientId: process.env.REACT_APP_CLIENT_ID || '',
  };
  const userPool = new CognitoUserPool(poolData);
  const user: CognitoUser | null = userPool.getCurrentUser();

  useEffect(() => {
    user?.getSession((error: Error, session: null | CognitoUserSession) => {
      if (error) {
        console.log(`ERROR`, error);
        return;
      }

      if (!session) {
        return;
      }

      axios.get('https://api.traillife7777.com/events', {
        headers: {
          authorization: session.getIdToken().getJwtToken(),
        },
      })
        .then(res => {
          const records = res.data.Items.map((record: IEvent) => {
            // Adding Date object using the date and time.
            record.date = new Date(record.Date);
            return record;
          });

          // Sort the records by the Date field.
          records.sort((obj1: IEvent, obj2: IEvent) => {
            return Date.parse(obj2.Date.toString()) - Date.parse(obj1.Date.toString());
          });

          setEvents(records);
        })
        .catch(err => {
          console.log(`ERROR`, err);
        });

      axios.get('https://api.traillife7777.com/images', {
        headers: {
          authorization: session.getIdToken().getJwtToken(),
        },
      })
        .then(res => {
          const BASE_URL = 'https://res.cloudinary.com/trail-life-nc-7777/image/upload/';

          const images = res.data.Items.map((image: any) => {
            return {
              ...image,
              Url: `${BASE_URL}${image.Url}`,
            }
          });

          setImages(images);
        })
        .catch(err => {
          console.log(`ERROR`, err);
        });
    });

    if (!user) {
      return;
    }
  }, []);

  if (!images.length && !events.length) {
    return '';
  }

  // Sort the images by the event date found in the events array.
  const sortedImages: TrailLifeImage[] = [];

  events.forEach((trailLifeEvent: TrailLifeEvent) => {
    images.filter((trailLifeImage: TrailLifeImage) => {
      return trailLifeImage.EventId === trailLifeEvent.EventId;
    }).forEach((trailLifeImage: TrailLifeImage) => {
      sortedImages.push(trailLifeImage);
    });
  });



  return (
    <Page pageRef={pageRef}>
      <h1 className="text-4xl md:text-3xl mb-4" ref={pageRef}>Photo Album</h1>
      <ImageCardList
        imagesList={sortedImages}
        eventsList={events}
        setIsOpen={setIsOpen}
        setPhotoIndex={setPhotoIndex}
      />
      <LightBoxWrapper
        images={sortedImages}
        isOpen={isOpen}
        photoIndex={photoIndex}
        setIsOpen={setIsOpen}
        setPhotoIndex={setPhotoIndex}
      />
    </Page>
  );
};

export default PhotoAlbumPage;
