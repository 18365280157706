import { useState } from 'react';
import woodlandsIcon from '../../assets/woodlands.jpg';
import navigatorsIcon from '../../assets/navigators.jpg';
import adventurersIcon from '../../assets/adventurers.jpg';

export interface CalenderEventFilterProps {
  filterHandler(troopLevel: string[]): void;
}

const CalendarEventFilter = (props: CalenderEventFilterProps) => {
  const [woodlandsIsChecked, setWoodlandsIsChecked] = useState(true);
  const [navigatorsIsChecked, setNavigatorsIsChecked] = useState(true);
  const [adventurersIsChecked, setAdventurersIsChecked] = useState(true);

  const getTroopLevelIndicator = (woodlands: boolean, navigators: boolean, adventurers: boolean) => {
    const woodlandsIndicator = '1';
    const navigatorsIndicator = '2';
    const adventurersIndicator = '3';
    const navAvIndicator = '4';
    const allTroopIndicator = '5';

    let levelIndicator: string[] = [];

    if (woodlands && !navigators && !adventurers) { // Woodlands only
      levelIndicator.push(woodlandsIndicator);
      levelIndicator.push(allTroopIndicator);
    } else if (!woodlands && navigators && !adventurers) { // Navs only
      levelIndicator.push(navigatorsIndicator);
      levelIndicator.push(navAvIndicator);
      levelIndicator.push(allTroopIndicator);
    } else if (woodlands && navigators && !adventurers) { // Woodlands and Navs only
      levelIndicator.push(woodlandsIndicator);
      levelIndicator.push(navigatorsIndicator);
      levelIndicator.push(navAvIndicator);
      levelIndicator.push(allTroopIndicator);
    } else if (woodlands && !navigators && adventurers) { // Woodlands and Advs only
      levelIndicator.push(woodlandsIndicator);
      levelIndicator.push(navigatorsIndicator);
      levelIndicator.push(navAvIndicator);
      levelIndicator.push(allTroopIndicator);
    } else if (!woodlands && !navigators && adventurers) { // Advs only
      levelIndicator.push(adventurersIndicator);
      levelIndicator.push(navAvIndicator);
      levelIndicator.push(allTroopIndicator);
    } else if (!woodlands && navigators && adventurers) { // Navs & Avs only
      levelIndicator.push(navigatorsIndicator);
      levelIndicator.push(adventurersIndicator);
      levelIndicator.push(navAvIndicator);
      levelIndicator.push(allTroopIndicator);
    } else if (woodlands && navigators && adventurers) { // Woodlands, Navs and Advs
      levelIndicator.push(woodlandsIndicator);
      levelIndicator.push(navigatorsIndicator);
      levelIndicator.push(adventurersIndicator);
      levelIndicator.push(navAvIndicator);
      levelIndicator.push(allTroopIndicator);
    }

    props.filterHandler(levelIndicator);
  }

  const handleWoodlandsOnChange = () => {
    getTroopLevelIndicator(!woodlandsIsChecked, navigatorsIsChecked, adventurersIsChecked);
    setWoodlandsIsChecked(!woodlandsIsChecked);
  }

  const handleNavigatorsOnChange = () => {
    getTroopLevelIndicator(woodlandsIsChecked, !navigatorsIsChecked, adventurersIsChecked);
    setNavigatorsIsChecked(!navigatorsIsChecked);
  }

  const handleAdventurersOnChange = () => {
    getTroopLevelIndicator(woodlandsIsChecked, navigatorsIsChecked, !adventurersIsChecked);
    setAdventurersIsChecked(!adventurersIsChecked);
  }

  return (
    <ul>
      <li className="mb-2">
        <input
          type="checkbox"
          autoComplete="off"
          id="woodlands"
          checked={woodlandsIsChecked}
          onChange={handleWoodlandsOnChange}
        />
        <label htmlFor="woodlands" className="inline-block border rounded px-2 py-1 ml-3 mb-3 text-2xl sm:text-base">
            <img src={woodlandsIcon} alt="Woodlands" className="inline mr-2" aria-hidden="true" />
            Woodlands
        </label>
      </li>
      <li className="mb-2">
        <input
          type="checkbox"
          autoComplete="off"
          id="navigators"
          checked={navigatorsIsChecked}
          onChange={handleNavigatorsOnChange}
        />
        <label htmlFor="navigators" className="inline-block border rounded px-2 py-1 ml-3 mb-3 text-2xl sm:text-base">
          <img src={navigatorsIcon} alt="Navigators" className="inline mr-2" aria-hidden="true" />
          Navigators
        </label>
      </li>
      <li className="mb-2">
        <input
          type="checkbox"
          autoComplete="off"
          id="adventurers"
          checked={adventurersIsChecked}
          onChange={handleAdventurersOnChange}
        />
        <label htmlFor="woodlands" className="inline-block border rounded px-2 py-1 ml-3 mb-3 text-2xl sm:text-base">
          <img src={adventurersIcon} alt="Adventurers" className="inline mr-2" aria-hidden="true" />
          Adventurers
        </label>
      </li>
    </ul>
  )
}

export default CalendarEventFilter;
