import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CognitoConfig } from '../../cognito-config';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import troopLogo from '../../assets/troop-logo-dark.png';
import Spinner from '../../components/Spinner/Spinner';
import ToggleIcon from '../../components/ToggleIcon/ToggleIcon';
import WelcomeMessage from '../../components/WelcomeMessage/WelcomeMessage';

const ForgotPasswordPage = () => {
  const pageRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [processing, setProcessing] = useState(false);
  const [progressStep, setProgressStep] = useState(1);
  const userPool = useMemo(() => new CognitoUserPool(CognitoConfig), []);

  useEffect(() => {
    window.scrollTo(0, 0);
    pageRef?.current?.focus();
  }, []);

  const handleUsernameOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleVerificationCodeOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVerificationCode(event.target.value);
  };

  const handlePasswordToggleOnClick = () => {
    setPasswordToggle(!passwordToggle);
  };

  // Checks the login status on page load, if user is logged, they are redirected to the app.
  const checkLoginStatus = useCallback(() => {
    const user: CognitoUser | null = userPool.getCurrentUser();

    if (user !== null) {
      navigate('/');
    }
  }, [userPool, navigate]);

  // Request a password reset.
  const handleStep1OnClick = () => {
    setProcessing(true);

    const userData = {
      Username: username.toLowerCase(),
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        console.log('call result: ' + result);
        setProcessing(false);
      },
      onFailure: function (err) {
        if (err.name === 'UserNotFoundException') {
          setErrorMessage(`We can't find that email address in our system.`);
        } else {
          setErrorMessage(err.message);
        }
        setProcessing(false);
        setProgressStep(5);
      },
      inputVerificationCode() {
        setProcessing(false);
        setProgressStep(2);
      },
    });
  };

  const handleStep2OnClick = () => {
    setProgressStep(3);
  };

  const handleStep3OnClick = () => {
    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess: () => {
        setProgressStep(4);
      },
      onFailure: (err) => {
        setErrorMessage(err.message);
        setProgressStep(5);
      },
    });
  };

  const getSpinner = () => {
    if (processing) {
      return (
        <Spinner cssClass="text-gray-400 inline ml-2"/>
      );
    }

    return '';
  };

  // Step 1: Show's the username field.
  const getProgressStep1 = () => {
    return (
      <div className="progress-step-1">
        <p className="mb-4">Fill out the form below to request a new password. If you know your email address,
          you may go to the {' '}<Link to="/login" className="text-emerald-700 font-bold underline">login</Link> page.</p>
        <label className="block mb-4">
          <span className="text-gray-700">Email Address</span>
          <input
            type="text"
            className="mt-1 block w-full"
            value={username}
            onChange={handleUsernameOnChange}
            maxLength={50}
          />
        </label>
        <div className="text-right">
          <button
            onClick={handleStep1OnClick}
            disabled={processing}
            className="border px-4 py-2 border-gray-500 disabled:border-gray-400 disabled:text-gray-400 disabled:w-30"
          >Request new Password {getSpinner()}</button>
        </div>
      </div>
    );
  };

  // Step 2. Asks for the verification code provided by email. The email is retrieved from AWS Cognito using the username.
  const getProgressStep2 = () => {
    return (
      <div className="progress-step-2">
        <p className="mb-4">Check you email for a verification code. Enter that code in the form below.</p>
        <label className="block mb-4">
          <span className="text-gray-700">Verification Code</span>
          <input
            type="text"
            className="mt-1 block w-full"
            value={verificationCode}
            onChange={handleVerificationCodeOnChange}
            maxLength={6}
          />
        </label>
        <div className="text-right">
          <button
            onClick={handleStep2OnClick}
            disabled={processing}
            className="border px-4 py-2 border-gray-500 disabled:border-gray-400 disabled:text-gray-400 disabled:w-30"
          >Next {getSpinner()}</button>
        </div>
      </div>
    );
  };

  const showPasswordField = () => {
    if (passwordToggle) {
      return '';
    }

    return (
      <div className="flex mb-4">
        <div>
          <label className="inline mb-4">
            <span className="text-gray-700">Password</span>
            <input
              type="password"
              className="mt-1 block w-full"
              value={password}
              onChange={handlePasswordOnChange}
              maxLength={30}
            />
          </label>
        </div>
        <div className="inline-flex items-end">
          <button className="ml-3 px-4 py-2 border-gray-500" onClick={handlePasswordToggleOnClick}>
            <ToggleIcon withSlash={true} cssClass="w-6"/>
          </button>
        </div>
      </div>
    );
  };

  const showClearTextPasswordField = () => {
    if (!passwordToggle) {
      return '';
    }

    return (
      <div className="flex mb-4" ref={pageRef}>
        <div>
          <label className="inline mb-4">
            <span className="text-gray-700">Password</span>
            <input
              type="text"
              className="mt-1 block w-full"
              value={password}
              onChange={handlePasswordOnChange}
              maxLength={30}
            />
          </label>
        </div>
        <div className="inline-flex items-end">
          <button className="ml-3 px-4 py-2 border-gray-500" onClick={handlePasswordToggleOnClick}>
            <ToggleIcon withSlash={false} cssClass="w-6"/>
          </button>
        </div>
      </div>
    );
  };

  // Step 3: Asks for a new password once the verification code is confirmed.
  const getProgressStep3 = () => {
    return (
      <div className="progress-step-3">
        <p className="mb-4">Enter your new password below.</p>

        {showPasswordField()}
        {showClearTextPasswordField()}

        <div className="text-right">
          <button
            onClick={handleStep3OnClick}
            disabled={processing}
            className="border px-4 py-2 border-gray-500 disabled:border-gray-400 disabled:text-gray-400 disabled:w-30"
          >Update Password {getSpinner()}</button>
        </div>
      </div>
    );
  };

  // Step 4: Display success message when password reset is successful.
  const getSuccessMessage = () => {
    return (
      <div className="progress-step-3">
        <p className="mb-4">Success! Your password has been reset.</p>
        <p>Go to the <Link to="/login" className="text-emerald-700 font-bold underline">login page</Link> to sign in.</p>
      </div>
    );
  };

  // Step 4: Display failure message when password reset is not successful.
  const getFailureMessage = () => {
    return (
      <div className="progress-step-3">
        <p className="mb-4">Hmm...something went wrong when resetting your password.</p>
        <p className="text-red-500 mb-4">{errorMessage}</p>
        <p>Please try again or contact us at <strong><a href="mailto:traillife7777@gmail.com">traillife7777@gmail.com</a></strong>
          {' '} so we can assist you.</p>
      </div>
    );
  };

  // If user is already logged in, redirect them to the app.
  useEffect(() => {
    checkLoginStatus();
  }, [checkLoginStatus]);

  return (
    <div className="md:h-full md:flex">
      <div className="mt-8 md:mt-auto md:grid md:grid-cols-2 md:gap-x-2 md:items-stretch h-full w-full">
        <div className="hidden flex flex-col items-center justify-center bg-zinc-700">
          <WelcomeMessage/>
        </div>
        <div className="flex items-center justify-center">
          <div className="w-80">
            <img src={troopLogo} alt="Troop Logo" className="w-60 mb-12"/>
            {progressStep === 1 ? getProgressStep1() : ''}
            {progressStep === 2 ? getProgressStep2() : ''}
            {progressStep === 3 ? getProgressStep3() : ''}
            {progressStep === 4 ? getSuccessMessage() : ''}
            {progressStep === 5 ? getFailureMessage() : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
