import { ChangeEvent, useMemo, useState } from 'react';
import Spinner from '../Spinner/Spinner';
import ToggleIcon from '../ToggleIcon/ToggleIcon';
import troopLogo from '../../assets/troop-logo-dark.png';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { CognitoConfig } from '../../cognito-config';
import { useNavigate } from 'react-router-dom';

export interface NewPasswordRequiredProps {
  username: string;
  password: string;
}

const NewPasswordRequired = (props: NewPasswordRequiredProps) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [processing, setProcessing] = useState(false);
  const userPool = useMemo(() => new CognitoUserPool(CognitoConfig), []);

  const handlePasswordOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handlePasswordToggleOnClick = () => {
    setPasswordToggle(!passwordToggle);
  };

  // const handleResetPasswordOnClick = () => {
  //     setProcessing(true);
  //     props.changePasswordOnClick();
  // }

  const handleLoginOnClick = () => {
    setProcessing(true);

    const authenticatedData = {
      Username: props.username,
      Password: props.password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticatedData);

    const userData = {
      Username: props.username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function onSuccess() {
        navigate('/');
      },
      onFailure: function onFailure(error: Error) {
        setProcessing(false);
        console.log(`LOGIN FAILED 1`, error);
      },
      newPasswordRequired: function onNewPasswordRequire(userSession) {

        delete userSession.email_verified;

        cognitoUser.completeNewPasswordChallenge(password, userSession, {
          onSuccess: function onSuccess() {
            navigate('/');
          },
          onFailure: function onFailure(error: Error) {
            setProcessing(false);

            if (error.name === 'InvalidParameterException') {
              setErrorMessage(`Sorry about this. We've made a mistake when setting up your account. Please email us at traillife7777@gmail.com and let us know about the issue. We'll get it fixed and let you know when you can try again.`);
            }

            console.log(`LOGIN FAILED 2`, error.message === 'Invalid attributes given, given_name is missing', error.name);
          },
        });
      },
    });
  };

  const getSpinner = () => {
    if (processing) {
      return (
        <Spinner cssClass="text-gray-400 inline ml-2"/>
      );
    }

    return '';
  };

  const showPasswordField = () => {
    if (passwordToggle) {
      return '';
    }

    return (
      <div className="flex mb-4">
        <div>
          <label className="inline mb-4">
            <span className="text-gray-700">Password</span>
            <input
              type="password"
              className="mt-1 block w-full"
              value={password}
              onChange={handlePasswordOnChange}
              maxLength={30}
            />
          </label>
        </div>
        <div className="inline-flex items-end">
          <button className="ml-3 px-4 py-2 border-gray-500" onClick={handlePasswordToggleOnClick}>
            <ToggleIcon withSlash={true} cssClass="w-6"/>
          </button>
        </div>
      </div>
    );
  };

  const showClearTextPasswordField = () => {
    if (!passwordToggle) {
      return '';
    }

    return (
      <div className="flex mb-4">
        <div>
          <label className="inline mb-4">
            <span className="text-gray-700">Password</span>
            <input
              type="text"
              className="mt-1 block w-full"
              value={password}
              onChange={handlePasswordOnChange}
              maxLength={30}
            />
          </label>
        </div>
        <div className="inline-flex items-end">
          <button className="ml-3 px-4 py-2 border-gray-500" onClick={handlePasswordToggleOnClick}>
            <ToggleIcon withSlash={false} cssClass="w-6"/>
          </button>
        </div>
      </div>
    );
  };

  const getPasswordField = () => {
    return (
      <div className="progress-step-3">
        <p className="mb-4">Enter your new password below.</p>

        {showPasswordField()}
        {showClearTextPasswordField()}

        <div className="text-right pb-8">
          <button
            onClick={handleLoginOnClick}
            disabled={processing}
            className="border px-4 py-2 border-gray-500 disabled:border-gray-400 disabled:text-gray-400 disabled:w-30"
          >Reset my Password {getSpinner()}</button>
        </div>
        {getErrorMessage()}
      </div>
    );
  };

  const getErrorMessage = () => {
    if (!errorMessage.length) {
      return '';
    }

    return (
      <p className="text-red-500">{errorMessage}</p>
    );
  };

  return (
    <div className="h-full flex items-center justify-center">
      <div className="w-80">
        <img src={troopLogo} alt="Troop Logo" className="w-60 mb-12"/>
        <h1 className="text-3xl pb-8">Reset your Password</h1>
        <div className="flex mb-4 flex-col">
          {getPasswordField()}
        </div>
      </div>
    </div>
  );
};

export default NewPasswordRequired;
