import { ChangeEvent, useEffect, useState } from 'react';
import './App.css';
import {
  CognitoUserPool,
  CognitoUserAttribute, CognitoUser, AuthenticationDetails,
} from 'amazon-cognito-identity-js';

function App() {
  const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID || '',
    ClientId: process.env.REACT_APP_CLIENT_ID || '',
  };

  const userPool = new CognitoUserPool(poolData);
  const [code, setCode] = useState('');
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authenticatedStatus, setAuthenticatedStatus] = useState(false);

  const handleCodeOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  const handleUsernameOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handleFirstNameOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const handleLastNameOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const handleEmailOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleCodeOnSubmit = () => {
    const user = new CognitoUser({
      Username: username.toLowerCase(),
      Pool: userPool,
    });

    user.confirmRegistration(code, true, function validate(error, result) {
      if (error) {
        console.log(error);
      }

      if (result) {
        console.log(result);
      }
    });
  };

  const handleResendOnSubmit = () => {
    const user = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    user.resendConfirmationCode(function validate(error, result) {
      if (error) {
        console.log(error);
      }

      if (result) {
        console.log(result);
      }
    });
  };

  const handleSignUpOnClick = () => {
    const emailAttr = new CognitoUserAttribute({
      Name: 'email',
      Value: email,
    });

    const firstNameAtr = new CognitoUserAttribute({
      Name: 'given_name',
      Value: firstName,
    });

    const lastNameAttr = new CognitoUserAttribute({
      Name: 'family_name',
      Value: lastName,
    });

    userPool.signUp(username, password, [emailAttr, firstNameAtr, lastNameAttr], [], function validate(error, result) {
      if (error) {
        console.log(`ERROR`, error);
      }

      if (result) {
        console.log(`RESULT`, result);
      }
    });
  };

  const handleCheckLogin = () => {
    const user: CognitoUser | null = userPool.getCurrentUser();

    if (user !== null) {
      setAuthenticatedStatus(true);
    }

    if (user === null) {
      setAuthenticatedStatus(false);
    }
  };

  const handleLoginOnClick = () => {
    const authenticatedData = {
      Username: username,
      Password: password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticatedData);

    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    window.localStorage.setItem('user', JSON.stringify(cognitoUser));

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function onSuccess() {
        console.log(`User is authenticated.`);
        // Redirect user here.
        setAuthenticatedStatus(true);
        console.log(`cognito user`, cognitoUser);
      },
      onFailure: function onFailure(err) {
        console.log(`error`, err);
        console.log(`User is NOT authenticated`);
        setAuthenticatedStatus(false);
      },
    });
  };

  // const getUser = () => {
  //   const userData = {
  //     Username: username,
  //     Pool: userPool
  //   };
  //
  //   return new CognitoUser(userData);
  // }

  const getUserDetails = () => {

    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser === null) {
      return;
    }

    cognitoUser.getUserAttributes(function (err, result) {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }

      if (result) {
        for (let i = 0; i < result.length; i++) {
          console.log(
            'attribute ' + result[i].getName() + ' has value ' + result[i].getValue(),
          );
        }
      }
    });
  };

  const handleSignOutOnClick = () => {
    const user: CognitoUser | null = userPool.getCurrentUser();

    if (user !== null) {
      user.signOut();
      handleCheckLogin();
    }
  };


  useEffect(() => {
    handleCheckLogin();
  }, [handleCheckLogin]);

  return (
    <div className="App">
      <main>
        <div>
          <h2>Sign up</h2>
          <button onClick={handleSignUpOnClick}>Sign Up</button>
          <br/><br/>
          <hr/>
          <br/><br/>

          <h2>Authenticate</h2>
          <label htmlFor="username">Username</label>
          <input type="text" onChange={handleUsernameOnChange} value={username} id="username"/>
          <br/>
          <br/>
          <label htmlFor="code">Auth Code</label>
          <input type="text" onChange={handleCodeOnChange} value={code} id="code"/>
          <br/>
          <br/>
          <button onClick={handleCodeOnSubmit}>Submit Code</button>
          <br/><br/>
          <hr/>
          <br/><br/>

          <h2>Resend Auth Code</h2>
          <label htmlFor="username">Username</label>
          <input type="text" onChange={handleUsernameOnChange} value={username} id="username"/>
          <br/>
          <br/>
          <button onClick={handleResendOnSubmit}>Resend Code</button>
          <br/><br/>
          <hr/>
          <br/><br/>

          <h2>Sign Up Form</h2>
          <label htmlFor="username">Username</label>
          <input type="text" onChange={handleUsernameOnChange} value={username} id="username"/>
          <br/>
          <br/>
          <label htmlFor="firstName">First Name</label>
          <input type="text" onChange={handleFirstNameOnChange} value={firstName} id="firstName"/>
          <br/>
          <br/>
          <label htmlFor="lastName">Last Name</label>
          <input type="text" onChange={handleLastNameOnChange} value={lastName} id="lastName"/>
          <br/>
          <br/>
          <label htmlFor="email">Email</label>
          <input type="text" onChange={handleEmailOnChange} value={email} id="email"/>
          <br/>
          <br/>
          <label htmlFor="password">Password - $P@ssw0rd#</label>
          <input type="password" onChange={handlePasswordOnChange} value={password} id="password"/>
          <br/>
          <br/>
          <button onClick={handleSignUpOnClick}>Sign Up</button>
          <br/><br/>
          <hr/>
          <br/><br/>

          <h2>Login</h2>
          <label htmlFor="username">Username</label>
          <input type="text" onChange={handleUsernameOnChange} value={username} id="username"/>
          <br/>
          <br/>
          <label htmlFor="password">Password - $P@ssw0rd#</label>
          <input type="password" onChange={handlePasswordOnChange} value={password} id="password"/>
          <br/>
          <br/>
          <button onClick={handleLoginOnClick}>Login</button>
          <br/><br/>
          <hr/>
          <br/><br/>

          <h2>Checking authentication status</h2>
          <p>Check if a user is logged in at the moment.</p>
          <button onClick={handleCheckLogin}>Check Now</button>
          <p>User is {authenticatedStatus ? 'authenticated' : 'not authenticated'}</p>
          <br/><br/>
          <hr/>
          <br/><br/>

          <h2>Sign out</h2>
          <p>Click button to log user out.</p>
          <button onClick={handleSignOutOnClick}>Logout</button>
          <p>User is {authenticatedStatus ? 'authenticated' : 'not authenticated'}</p>

          <h2>Get User Details</h2>
          <button onClick={getUserDetails}>Get Details</button>
        </div>
      </main>
    </div>
  );
}

export default App;
